import React, {lazy, Suspense} from 'react';
import { Button, Position, Toast, Toaster, Classes, Slider, Drawer, Divider ,Alert, Intent} from "@blueprintjs/core";
import './styles/css/App.css';
import firebase from "./utils/firebaseSetUp";
import Loading from "./components/Loading";
import SideNav from "./components/SideNav";
import logo from "./res/dmc-white-logo.png";
import Orders from "./components/Orders";
//import ClientUsers from './components/ClientUsers';



//Components
const Auth = lazy(() => import("./components/Auth"));
const UpdateInventory = lazy(() => import("./components/UpdateInventory"));
const Clients = lazy(() => import("./components/Clients"));
const Configuration = lazy(() => import("./components/Configuration"));
const Home = lazy(() => import("./components/Home"));
const Vendors = lazy(() => import("./components/Vendors"));
const ClientsOrders = lazy(() => import("./components/ClientsOrders"));
const ClientUsers = lazy(() => import("./components/ClientUsers"));

class App extends React.Component {
  _mounted = false;

  constructor(props){
    super(props);

    this.state = {
      auth:false,
      screen:0,
      toasts:[],
      admin:false,
      toast:{
        state:false,
        message:""
      },
      alert:{
        isOpen:false,
        intent:"",
        text:"",
        icon:"issue",
        success:() => {

        }
      },
      isLoading:false,
      navStatus:"expanded",
      device:"expanded"
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  componentDidMount(){
    this._mounted  = true;

    const myFunction =(x)=> {
      if (x.matches) { // If media query matches
        if(this._mounted){
          this.setState({
            device:"small"
          })
        }
      } else {
        if(this._mounted){
          this.setState({
            device:"expanded"
          })
        }
      }
    }
    
    var x = window.matchMedia("(max-width: 800px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction)

    firebase.auth().onAuthStateChanged((u) =>{
      if (u) {
        // User is signed in.
        firebase.firestore().collection("usuarios").doc(firebase.auth().currentUser.uid).get()
        .then(user => {

          if(user.data().tipo === "admin"){
            if(this._mounted){
              this.setState({
                auth:true,
                screen:4,
                admin:true,
              })
            }
            
          }else {
            if(this._mounted){
              this.setState({
                auth:false,
                admin:false
              })
            }

            this.addToast("Solo administradores")
          }
        })
        .catch(e => {
          console.log(e)

          if(this._mounted){
            this.setState({
              toast:{
                state:true,
                message:"Algo salió mal"
              }
            })
          }
          this.addToast("Algo salio mal")
        })
        // ...
      } else {
        // User is signed out.
        // ...
        if(this._mounted){
          this.setState({
            isLoading:false,
            screen:0,
            auth:false
          })
        }
      }
    });
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  setNavStatus = (status) => {
    if(this._mounted){
      this.setState({
        navStatus:status
      })
    }
  }

  openAlert = (state, text, icon, intent, cb) => {
    
    if(this._mounted){
      this.setState({
        alert:{
          isOpen:state,
          text:text,
          icon:icon,
          intent:intent,
          success:cb
        }
      })
    }
  }

  render(){
    return (
      <>
      <Alert 
      isOpen={this.state.alert.isOpen}
      icon={this.state.alert.icon}
      confirmButtonText="Si"
      cancelButtonText="No"
      intent={this.state.alert.intent}
      onConfirm={() => {
        this.state.alert.success(() => {
          let base = Object.assign({}, this.state.alert);
          base.isOpen = false;
          if(this._mounted){
            this.setState({
              alert:base
            })
          }
        })
      }}
      onCancel={() => {
        let base = Object.assign({}, this.state.alert);
        base.isOpen = false;
        if(this._mounted){
          this.setState({
            alert:base
          })
        }
      }}
      >
        <p>
          {this.state.alert.text}
         </p>
      </Alert>
      {this.state.device === "small" && this.state.auth === true?<nav className="navbar navbar-expand-md bg-dark navbar-dark sticky-top">
      <Alert 
      isOpen={this.state.alert.isOpen}
      icon={this.state.alert.icon}
      confirmButtonText="Si"
      cancelButtonText="No"
      onConfirm={() => {
        this.state.alert.success(() => {
          let base = Object.assign({}, this.state.alert);
          base.isOpen = false;
          if(this._mounted){
            this.setState({
              alert:base
            })
          }
        })
      }}
      onCancel={() => {
        let base = Object.assign({}, this.state.alert);
        base.isOpen = false;
        if(this._mounted){
          this.setState({
            alert:base
          })
        }
      }}
      >
        <p>
          {this.state.alert.text}
         </p>
      </Alert>

<a className="navbar-brand" href="#"><img src={logo} width="100px"/></a>


<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
  <span className="navbar-toggler-icon"></span>
</button>


<div className="collapse navbar-collapse" id="collapsibleNavbar">
  <ul className="navbar-nav ">
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        if(this._mounted){
          this.setState({
            screen:4
          })
        }
      }}><span className="mr-2">Inicio</span> <i className="material-icons align-middle">home</i></a>
    </li>
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        if(this._mounted){
          this.setState({
            screen:1
          })
        }
      }}><span className="mr-2">Inventario</span> <i className="material-icons align-middle">list_alt</i></a>
    </li>
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        if(this._mounted){
          this.setState({
            screen:5
          })
        }
      }}><span className="mr-2">Vendedores</span> <i className="material-icons align-middle">person</i></a>
    </li>
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        if(this._mounted){
          this.setState({
            screen:2
          })
        }
      }}><span className="mr-2">Clientes</span> <i className="material-icons align-middle">storefront</i></a>
    </li>
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        if(this._mounted){
          this.setState({
            screen:3
          })
        }
      }}><span className="mr-2">Configuración</span> <i className="material-icons align-middle">settings</i></a>
    </li>
    <li className="nav-item ml-auto">
      <a className="nav-link" href="#" onClick={e => {
        e.preventDefault();
        firebase.auth().signOut();
      }}><span className="mr-2">Cerrar Sesión</span> <i className="material-icons align-middle">power_settings_new</i></a>
    </li>
  </ul>
</div>
</nav>:null}
    <div className="container-fluid p-1">
     
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
      {this.state.auth === true?<SideNav
      setStatus={this.setNavStatus}
       items={
        [
          {
            icon:"home",
            text:"Inicio",
            active:this.state.screen === 4?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:4
                })
              }
            }
          },
          this.state.admin === true?
          {
            icon:"list_alt",
            text:"Inventario",
            active:this.state.screen === 1?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:1
                })
              }
            }
          }:null,
          {
            icon:"assignment",
            text:"Pedidos",
            active:this.state.screen === 6?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:6
                })
              }
            }
          },
          {
            icon:"shopping_cart",
            text:"Pedidos Clientes",
            active:this.state.screen === 7?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:7
                })
              }
            }
          },
          {
            icon:"person",
            text:"Vendedores",
            active:this.state.screen === 5?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:5
                })
              }
            }
          },
          {
            icon:"supervisor_account",
            text:"Clientes Usuarios",
            active:this.state.screen === 8?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:8
                })
              }
            }
          },

          
          {
            icon:"storefront",
            text:"Clientes",
            active:this.state.screen === 2?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:2
                })
              }
            }
          },
          
          this.state.admin === true?
          {
            icon:"settings",
            text:"Configuración",
            active:this.state.screen === 3?true:false,
            onClick:() => {
              if(this._mounted){
                this.setState({
                  screen:3
                })
              }
            }
          }:null,
          {
            icon:"power_settings_new",
            text:"Cerrar Sesión",
            onClick:() => {
              if(this._mounted){
                this.setState({
                  isLoading:true
                })
              }
              firebase.auth().signOut()
              .then(e => {
                if(this._mounted){
                  this.setState({
                    isLoading:false
                  })
                }
              })
              .catch(e => {
                console.log(e)
                if(this._mounted){
                  this.setState({
                    isLoading:false
                  })
                }
              })
              ;
            }
          }
        ]
      } />:null}
      {this.state.isLoading?<Loading />:null}
    <Suspense fallback={<Loading/>}>
      {this.state.screen === 0 && this.state.auth == false?
      <Auth
      device={this.state.device}
       addToast={this.addToast}/>:null}
      {this.state.screen === 1 && this.state.auth === true?
      <UpdateInventory 
      openAlert={this.openAlert}
      device={this.state.device}
      addToast={this.addToast}
       navStatus={this.state.navStatus}/>:null}

       {this.state.screen === 2 && this.state.auth === true?
       <Clients device={this.state.device} type={this.state.admin === true?"admin":"vendor"} addToast={this.addToast}
       navStatus={this.state.navStatus}
       />:null
      }

      {this.state.screen === 3 && this.state.auth === true?
      
      <Configuration
      device={this.state.device}
       addToast={this.addToast} navStatus={this.state.navStatus} />
      :null
      }

      {this.state.screen === 4 && this.state.auth === true?<Home
      device={this.state.device}
      setScreen={n => {
        if(this._mounted){
          this.setState({
            screen:n
          })
        }
      }}
      addToast={this.addToast}
      navStatus={this.state.navStatus} 
      />:null}

      {this.state.screen ===5 ?<Vendors
      device={this.state.device}
       addToast={this.addToast}
       navStatus={this.state.navStatus} 
      />:null}

      {this.state.screen ===6?<Orders
      device={this.state.device}
      addToast={this.addToast}
      navStatus={this.state.navStatus} 
      />:null}

{this.state.screen ===7?<ClientsOrders
      device={this.state.device}
      addToast={this.addToast}
      navStatus={this.state.navStatus} 
      />:null}

      {this.state.screen === 8?<ClientUsers
      device={this.state.device}
      addToast={this.addToast}
      navStatus={this.state.navStatus} 
      />:null}
    </Suspense>
    </div>
    </>
    )
  }
}

export default App;
