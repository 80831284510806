import React from "react";
import $ from "jquery";
import logo from "../res/dmc-white-logo.png";

export default class SideNav extends React.Component{
    _mounted = false;
    constructor(props){
        super(props);

        this.state ={
            pose:0
        }
    }

    componentDidMount(){
        this._mounted = true
    }

    componentWillUnmount(){
        this._mounted = false
    }

    shrink = () => {
        $(".side-nav")
        .animate({
            minWidth:"80px",
            width:"80px"
        }, 300, () => {
            if(this._mounted){
                this.setState({
                    pose:1
                })
            }
        }).addClass("side-nav-shrinked")
        .css({
            minWidth:"80px",
            width:"80px"
        })

        this.props.setStatus("shrinked");
        
        
    }

    expand = () => {
        $(".side-nav")
        .animate({
            minWidth:"300px",
            width:"300px"
        }, 300, () => {
            if(this._mounted){
                this.setState({
                    pose:0
                })
            }
        }).removeClass("side-nav-shrinked")
        .css({
            minWidth:"300px",
            width:"300px"
        })
        this.props.setStatus("expanded");
        
        
    }

    render(){
        return(
            <div className="side-nav shadow-sm">
                <div className="flex-center mt-3 mb-2 img">
                    <img src={logo} width={this.state.pose === 1?"60px":"200px"}/>
                </div>
                <ul>
                    {this.props.items.map((e,i) => {
                        if(e !== null){
                        return (
                            <>
                            <li key={i} onClick={e.onClick} className={`${e.active === true?"active":""}`}>
                              <span><i className="material-icons align-middle">{e.icon}</i></span>
                              <span><a href="#" onClick={e => {e.preventDefault()}}>{e.text}</a></span>
                            </li>
                            <hr/>
                            </>
                        )
                        }else {
                            return null
                        }
                    })}
                    <li className="close-btn" onClick={() => {
                        if(this.state.pose === 0){
                            this.shrink()
                        }else {
                            this.expand()
                        }
                    }}>
                        <span><i className="material-icons align-middle">{this.state.pose === 0?"chevron_left":"chevron_right"}</i></span>
                    </li>
                </ul>
            </div>
        )
    }
}