import React from "react";
import Loading from "./Loading";
import CompleteLoading from "./CompleteLoading";
import firebase from "../utils/firebaseSetUp";
import OrderItem from "./OrderItem";

import $ from "jquery";

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


export default class Orders extends React.Component {
    _ordersListener;
    _mounted;
    constructor(props){
        super(props);

        this.state = {
            completeLoading:"",
            orders:[],
            loadMore:false,
            lastDocument:null,
            loadMorePending:false,
            search:"",
            screen:0,
            data:null
        }
    }

    loadMoreOrders = () => {
        if(this._mounted){
            this.setState({
               loadMorePending:true
            })
         }
 
         
         let base = firebase.firestore().collection("ventas");
 
         if(this.state.search !== ""){
             base = base.where("palabrasClave","array-contains",String(this.state.search).toLowerCase());
         }
 
         base.orderBy("creado","desc").startAfter(this.state.lastDocument).limit(10).get().then(snap => {
             let orders = [];
 
             snap.forEach(doc => {
                 let data = doc.data();
                 data.id = doc.id;
                 orders.push(data);
             })
 
             if(this._mounted){
                 this.setState({
                     orders:this.state.orders.concat(orders),
                     loadMore:snap.docs.length <10?false:true,
                     lastDocument:snap.docs[snap.docs.length - 1],
                     pending:false,
                     loadMorePending:false
                 })
             }
         })
         .catch(e => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    loadMorePending:false,
                    loadMore:false
                })
            }
         })
    }

    getOrders = () => {
        if(this._mounted){
           this.setState({
              pending:true
           })
        }

        if(this._ordersListener !== undefined){
            this._ordersListener()//CLean the listener
        }
        let base = firebase.firestore().collection("ventas");

        if(this.state.search !== ""){
            base = base.where("palabrasClave","array-contains",String(this.state.search).toLowerCase());
        }

        this._ordersListener = base.orderBy("creado","desc").limit(10).onSnapshot(snap => {
            let orders = [];

            snap.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                orders.push(data);
            })

            if(this._mounted){
                this.setState({
                    orders:orders,
                    loadMore:snap.docs.length <10?false:true,
                    lastDocument:snap.docs[snap.docs.length - 1],
                    pending:false
                })
            }
        }, (e) => {
            console.log(e);
            if(this._mounted){
                this.setState({
                    pending:false,
                    loadMore:false
                })
            }
        })

    }

    componentDidMount(){
        this._mounted = true;

        this.getOrders();

        $(".special-search").on('keypress',(e) =>{
            if(e.which == 13) {
                this.getOrders();
            }
        });
    }

    componentWillUnmount(){
        this._mounted = false;

        if(this._ordersListener !== undefined){
            this._ordersListener()//Clean the listener
        }
    }

    render(){
        return <div className="container-fluid fade-in" style={{
            paddingLeft:this.props.device === "small"?"20px":this.props.navStatus === "expanded"?"320px":"100px", 
            paddingTop:"20px",
            paddingRight:"20px",
            paddingBottom:"20px"
            }}>

{                  this.state.completeLoading !==""?<CompleteLoading message={this.state.completeLoading}/>:null}
                    {this.state.isLoading?<Loading/>:null}
                <h2 className="text-center font-weight-bold">Pedidos</h2>


                {this.state.screen === 0?<>

                <div className="input-group mb-3 mt-4">
  <input type="text" value={this.state.search} className="form-control special-search" placeholder="Search" onChange={e => {
      if(this._mounted){
          this.setState({
              search:e.currentTarget.value
          })
      }
  }}/>
  <div className="input-group-append">
    <button className="btn btn-primary" onClick={this.getOrders} type="submit"><i className="material-icons align-middle" style={{fontSize:"18px"}}>search</i></button>
  </div>
</div>

{this.state.pending === true?<div className="flex-left mt-2"><div className="spinner-border"></div></div>:(() => {
                        let elements = []
                      
                       for(let i =0; i < this.state.orders.length; i +=2){
                        
                           elements.push(<div className="row m-0" key={i}>
                               <div className="col-lg-6 m-0 p-2">
                                   <OrderItem
                                   
                                   changeState={(id, value) => {
                                       if(this._mounted){
                                           this.setState({
                                               completeLoading:"Actualizando Venta..."
                                           })
                                       }

                                     

                                       firebase.firestore().collection("usuarios").doc(id).update({
                                           activo:value
                                       })
                                       .then(() => {
                                           if(this._mounted){
                                               this.setState({
                                                   completeLoading:""
                                               })
                                           }

                                           this.props.addToast("Vendedor actualizado!")
                                       })
                                       .catch(e => {
                                           console.log(e);
                                           if(this._mounted){
                                               this.setState({
                                                   completeLoading:""
                                               })
                                           }
                                           this.props.addToast("Algo salió mal")
                                       })
                                   }}
                                    order={this.state.orders[i]} 

                                    accept={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Aprobando Venta"
                                            })
                                        }

                                        firebase.firestore().collection("ventas").doc(this.state.orders[i].id).update({
                                            estadoAprobacion:"aprobado"
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }

                                            this.props.addToast("Venta Aprobada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    delete={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Eliminando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i].productos[j].cantidad)
                                            })
                                        }

                                       // batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i]))
                                       batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i].id))

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Eliminada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    deny={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Rechazando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i].productos[j].cantidad)
                                            })
                                        }

                                       // batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i]))
                                       batch.update(firebase.firestore().collection("ventas").doc(this.state.orders[i].id), {
                                        estadoAprobacion:"rechazado"
                                    })

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Rechazada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}
                                   
                                   view={() => {
                                    if(this._mounted){
                                        this.setState({
                                            data:this.state.orders[i],
                                            screen:1
                                        })
                                    }
                                }}
                                  

                                  
                                    />
                               </div>
                               
                               <div className="col-lg-6 p-2 m-0">
                                   {i +1 === this.state.orders.length?null:
                                   <OrderItem

                                    view={() => {
                                        if(this._mounted){
                                            this.setState({
                                                data:this.state.orders[i+1],
                                                screen:1
                                            })
                                        }
                                    }}

                                    changeState={(id, value) => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Actualizando Vendedor..."
                                            })
                                        }
 
                                      
 
                                        firebase.firestore().collection("usuarios").doc(id).update({
                                            activo:value
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
 
                                            this.props.addToast("Vendedor actualizado!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal")
                                        })
                                    }}

                                   
                                   
                                    order={this.state.orders[i+1]}

                                    accept={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Aprobando Venta"
                                            })
                                        }

                                        firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id).update({
                                            estadoAprobacion:"aprobado"
                                        })
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }

                                            this.props.addToast("Venta Aprobada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    delete={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Eliminando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i+1].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i+1].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i+1].productos[j].cantidad)
                                            })
                                        }

                                        batch.delete(firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id))

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Eliminada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}

                                    deny={() => {
                                        if(this._mounted){
                                            this.setState({
                                                completeLoading:"Rechazando Venta"
                                            })
                                        }

                                        let batch = firebase.firestore().batch();

                                        for(let j =0; j < this.state.orders[i+1].productos; j++){
                                            batch.update(firebase.firestore().collection("productos").doc(this.state.orders[i+1].productos[j].idProducto), {
                                                actualizado:new Date(),
                                                disponibilidad:firebase.firestore.FieldValue.increment(this.state.orders[i+1].productos[j].cantidad)
                                            })
                                        }

                                       batch.update(firebase.firestore().collection("ventas").doc(this.state.orders[i+1].id), {
                                           estadoAprobacion:"rechazado"
                                       })

                                        batch.commit()
                                        .then(() => {
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Venta Rechazada!")
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            if(this._mounted){
                                                this.setState({
                                                    completeLoading:""
                                                })
                                            }
                                            this.props.addToast("Algo salió mal");
                                        })
                                    }}
                                    
                                    />}


                               </div>
                           </div>)
                       }

                       return elements
                    })()}

{this.state.loadMore === true?<div className="mt-4 flex-center">
                       {this.state.loadMorePending?<div className="spinner-border"></div>:<a onClick={e => {
                           e.preventDefault();
                           this.loadMoreOrders()
                       }}>Cargar más</a>}
                    </div>:null}
                    </>:null}

                    {this.state.screen === 1?<div className="container-fluid mt-4 show-in">
                    <div className="flex-left">
                        <button className="btn btn-danger btn-sm" onClick={() => {
                            if(this._mounted){
                                this.setState({
                                    screen:0
                                }, () => {
                                    
                                })
                            }
                            
                        }}>
                            <i className="material-icons align-middle">arrow_back</i>
                            <span className="ml-2">Atras</span>
                        </button>
                    </div>
                    

                    <h3 className="mt-5">Información</h3>

                    <div className="white-card mt-3">
                    <div className="row">
                        <div className="col-sm-6 m-0">
                            <label>Cliente</label>
                            <h5>{this.state.data.nombreCliente}</h5>
                        </div>
                        <div className="col-sm-6 m-0">
                            <label>Vendedor</label>
                            <h5>{this.state.data.vendedor}</h5>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-6 m-0">
                            <label>Forma de Pago</label>
                            <h5>{this.state.data.formaPago === 0?"Pago en dólares":this.state.data.formaPago === 1?"Pago en bolivares":this.state.formaPago === 2?"Contado":"Crédito"}</h5>
                        </div>

                        <div className="col-sm-6 m-0">
                            <label>Creado</label>
                            <h5>{this.state.data.creado.toDate().getDate() + "-" + (this.state.data.creado.toDate().getMonth() + 1) + "-" + this.state.data.creado.toDate().getFullYear() + " " + this.state.data.creado.toDate().getHours() + ":" + this.state.data.creado.toDate().getMinutes()}</h5>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-6 m-0">
                            <label>Estado</label>
                            <h5 className={`${this.state.data.estadoAprobacion === "pendiente"?"primary-text":this.state.data.estadoAprobacion === "aprobado"?"text-success":"text-danger-2"}`}>{capitalize(this.state.data.estadoAprobacion)}</h5>
                        </div>

                       
                    </div>
                    </div>

                    <h3 className="mt-4">Total</h3>

                    <div className="white-card mt-3">
                    <div className="row mx-0">
                        <div className="col-sm-6 m-0">
                            <label>Sub Total</label>
                            <h5>{Math.round(this.state.data.subTotal * 100)/100}</h5>
                        </div>
                        <div className="col-sm-6 m-0">
                            <label>Total</label>
                            <h5>{Math.round(this.state.data.total *100)/100}</h5>
                        </div>
                    </div>

                    </div>


                    <h4 className="mt-4">Información Adicional</h4>

                    <div className="white-card mt-3">
                        <div className="row mx-0">
                            <div className="col-sm-12">
                                <label>Comentarios</label>
                                <h5>{(this.state.data.comentario !== undefined && this.state.data.comentario)?this.state.data.comentario:"No especificado"}</h5>
                            </div>
                        </div>
                    </div>

                    

                    <h3 className="mt-4">Productos</h3>

                    <div className="table-responsive mt-3">
                        <table className="table">
                            <thead>
              <th>Código</th>
              <th>Nombre</th>
              <th>Precio USD</th>
              <th>Cantidad</th>
             
                            </thead>
                            <tbody>
                                {this.state.data.productos.map((e,i) => {
                                    return <tr key={i}>
                                        <td>
                      {e.codigo}
                  </td>
                  <td>
                      {e.nombre}
                  </td>
                  <td>
                      {e.precioUSD}
                  </td>
                  <td>
                      {e.cantidad}
                  </td>
                  
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>:null}
        </div>
    }
}

