import React from "react";

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export default function OrderItem(props) {
    return <div className="order-item shadow-sm mt-4">
        <div className="order-header">
            <div className="flex-between">
                <p>{props.order.creado.toDate().getDate() + "-" + (props.order.creado.toDate().getMonth() + 1) + "-" + props.order.creado.toDate().getFullYear() + " " + props.order.creado.toDate().getHours() + ":" + props.order.creado.toDate().getMinutes() }</p>

               
            </div>
        </div>
        <div className="order-body">
        <div className="row">
           
            <div className="col-8">
            <h5><b>Información</b></h5>
                <p className="client-item-text mt-3"><b>Cliente:</b> {props.order.nombreCliente}</p> 
                <p className="client-item-text mt-2"><b>Vendedor:</b> {props.order.vendedor}</p>
                
            </div>

            <div className="col-4">
                <h5><b>Total</b></h5>
                <p className="client-item-text mt-3"><b>Sub Total:</b> {Math.round(props.order.subTotal *100)/100}</p> 
                <p className="client-item-text mt-2"><b>Total:</b> {Math.round(props.order.total *100)/100}</p>
            </div>
        </div>
        </div>

        <div className="order-footer">
            <div className="flex-between">
<p className={`${props.order.estadoAprobacion === "pendiente"?"primary-text":props.order.estadoAprobacion === "aprobado"?"text-success":"text-danger-2"}`}>{capitalize(props.order.estadoAprobacion)}</p>

{props.order.estadoAprobacion === "pendiente"?<div className="flex-end" >
    <button className="btn btn-success btn-sm" onClick={()=> {
    props.accept();
}}>
        <i className="material-icons align-middle">done</i>
    </button>
    <button className="btn btn-danger btn-sm ml-2" onClick={() => {
        props.deny();
    }}>
        <i className="material-icons align-middle">clear</i>
    </button>
</div>:null}
            </div>
        </div>

        <div className="action-buttons">
            <div className="flex-center">
                <button className=" btn " onClick={() => {
                    props.view();
                }}>
                    <i className="material-icons align-middle">visibility</i>
                </button>
                
                <button className="btn" onClick={() => {
                    props.delete();
                }}>
                    <i className="material-icons align-middle">clear</i>
                </button>
            </div>
        </div>
    </div>
}